<template>
  <div class="header">
    <nav-view class="header-top"></nav-view>
    <banner-view class="header-banner"></banner-view>
  </div>
</template>

<script>
import NavView from "./NavView";
import BannerView from "./BannerView";
export default {
  name: "HeaderView",
  components: {
    NavView,
    BannerView
  },
  computed: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.header{
  width: 100%;
  position: relative;
  &-top{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &-banner{
    width: 100%;
  }
}

</style>