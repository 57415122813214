<template>
  <div class="PlatformIntroduction">
    <div class="PlatformIntroduction-title">
      <img :src="title" alt="">
    </div>
    <div class="PlatformIntroduction-text">
      遵旅云旅游生态运营平台是旅游多行业集中管理运营旅游综合平台，致力于建设城市智慧旅游的服务平台，
      整合旅游产业资源，以市场化方式推动大数据与旅游、餐饮、酒店等涉旅行业的融合， 形成数字化旅游产业体系，
      为政府、涉旅企业提供旅游数据归集、智慧旅游管理、 智慧旅游服务，促进城市数字旅游经济的发展
    </div>
    <div class="PlatformIntroduction-box f-r-c">
      <div class="card" v-for="(item,index) in card" :key="index">
        <div class="icon-box">
          <app-icon :name="item.icon"
                    size="68px"
                    class="icon"
          />
        </div>

        <div class="name">{{ item.name }}</div>
        <div class="info">{{ item.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformIntroduction",
  components: {

  },
  computed: {

  },
  data() {
    return {
      title:require('@/assets/image/content/platform-introduction.png'),
      card:[
        {
          icon:'xitong',
          name:'系统管理',
          info:'岗位角色的分配和角色权限的配置',
        },
        {
          icon:'chanpin',
          name:'产品管理',
          info:'产品属性自定义及班次设置等等',
        },
        {
          icon:'piaowu',
          name:'票务管理',
          info:'对票务相关信息进行设置和查询',
        },
        {
          icon:'xiaoshou',
          name:'销售管理',
          info:'商品价格和折扣设置、销售激励规则设置',
        },
        {
          icon:'caigou',
          name:'采购管理',
          info:'采购全流程管理和数据汇总',
        },
        {
          icon:'kucun',
          name:'库存管理',
          info:'开关账、库存作业、库存报表等管理',
        },
        {
          icon:'xianjin',
          name:'现金管理',
          info:'现金收入、支出操作及报表汇总',
        },
        {
          icon:'chenben',
          name:'成本管理',
          info:'对经营各环节产生的成本进行统计和预估',
        },
        {
          icon:'zichan',
          name:'资产管理',
          info:'以资产卡片管理为基础，实现对资产的全面控制',
        },
        {
          icon:'baobiao',
          name:'报表管理',
          info:'结合各功能数据汇总，生成多维度图形或数字报表',
        },


      ],
    };
  },
  mounted() {},
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.PlatformIntroduction{
  width: 100%;
  height: 1078px;
  background-image: url("~@/assets/image/content/bg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &-title{
    width: 448px;
    height: 80px;
    padding-top: 52px;
  }

  &-text{
    padding: 33px 148px;
    font-size: 20px;
    font-weight: 400;
    color: #424242;
  }

  &-box{
    height: 690px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (min-width: 1800px){
      padding: 0 100px;
    }

    .card{

      flex: 0 0 18%;
      height: 346px;
      background: rgba(255,255,255,0.4);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .icon-box{
        margin-top: 56px;
        border-radius: 50%;
        border: solid 2px #5691fc;
        .icon{
          color: #5691fc;
        }
      }
      .name{
        margin-top: 23px;
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
      .info{
        padding: 0 20px;
        text-align: center;
        margin-top: 24px;
        font-size: 22px;
        font-weight: 400;
        color: #666666;
        letter-spacing: 1px;
      }

      &:hover{
        background: #386DCC;
        transition-duration:1s;
        .icon-box{
          border: solid 2px #ffffff;
          .icon{
            color: #ffffff;
          }
        }
        .name{
          color: #ffffff;
        }
        .info{
          color: #ffffff;
        }
      }
    }


  }

}
.f-r-c{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 18px;
}
</style>