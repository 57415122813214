<template>
  <div class="app">
    <header-view></header-view>
    <content-view></content-view>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/views/header/HeaderView"
import ContentView from "@/views/content/ContentView"
import FooterView from "@/views/footer/FooterView";
export default {
  name: "HomeView",
  components: {
    HeaderView,
    ContentView,
    FooterView,
  },
  computed: {

  },
  data() {
    return {
      index: '',
    };
  },
  mounted() {},
  methods: {

  },
};
</script>

<style lang="scss">


</style>