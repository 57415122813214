import { createStore } from 'vuex'

export default createStore({
  state: {
    // 定义一个name，以供全局使用
    name: 'home',

  },
  getters: {
  },
  mutations: {
    setName(state, number) {
      // 增加一个带参数的mutations方法
      state.name = number;
    },

  },
  actions: {
  },
  modules: {
  }
})
