<template>
  <div class="ServiceObject">
    <div class="ServiceObject-title">
      <img :src="title" alt="">
    </div>
    <div class="ServiceObject-box f-r-c">
      <div class="ServiceObject-box-bg"></div>
      <div class="ServiceObject-box-button"
           v-for="(item,index) in button"
           :key="index"
           @click="selectBtn(index)"
      >
        <app-icon :name="item.icon"
                  size="40px"
                  class="icon-color"
                  :style="index == active?'color:#386DCC;':''"
        />
        <div class="ServiceObject-box-button-line"
             :style="index == active?'background:#386DCC;':''"
        >
        </div>
        <div class="name"
             :style="index == active?'color:#386DCC;font-weight: 500;':''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ServiceObject-content f-r-c">
      <div class="ServiceObject-content-img" :style="{
        'background-image' : 'url(' + button[active].img + ')'
      }">
<!--        <img :src="button[active].img" alt="">-->
      </div>
      <div class="ServiceObject-content-info">
        <div class="title-box">
          <div class="title-bg">{{ button[active].english }}</div>
          <div class="title">{{ button[active].name }} <span class="title-english">{{ button[active].nameEenglish }}</span> </div>
        </div>
        <div class="line"></div>
        <div class="info-box">
          {{ button[active].info }}
        </div>
        <div class="btn" @click="goAdmin(button[active].key)">立即登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceObject",
  components: {

  },
  computed: {

  },
  data() {
    return {
      title:require('@/assets/image/content/service-object.png'),
      button:[
        {
          icon:'jingqu',
          name:'景区',
          english:'ONE',
          nameEenglish:'SCENIC',
          key:'scenic',
          img:require('@/assets/image/content/scenic.jpg'),
          info:'景区门票发布  | 景区商品发布 | 销售渠道对接与管理 | 景区客流及门票销售数据'
        },
        {
          icon:'lvxingshe',
          name:'旅行社',
          english:'TWO',
          nameEenglish:'TRAVEL',
          key:'travel',
          img:require('@/assets/image/content/travel.png'),
          info:'景区门票预定  | 酒店预定 | 组合商品预定 | 订单查询 | 对账结算',
        },
        {
          icon:'jiudian',
          name:'酒店',
          english:'THREE',
          nameEenglish:'HOTEL',
          key:'hotel',
          img:require('@/assets/image/content/hotel.png'),
          info:'酒店商品发布  |  酒店组合商品发布 | 销售渠道对接与管理 | 全渠道订单管理 '
        },
        {
          icon:'gouwu',
          name:'购物店',
          english:'FOUR',
          nameEenglish:'SHOPPING',
          key:'shopping',
          img:require('@/assets/image/content/shopping.png'),
          info:'购物店发布  | 购物店订单查询  |  销售渠道对接 | 销售渠道管理'
        },
        {
          icon:'canyin',
          name:'餐饮',
          english:'FIVE',
          nameEenglish:'RESTAURANT',
          key:'restaurant',
          img:require('@/assets/image/content/restaurant.png'),
          info:'菜品统一销售 | 订单统一管理 | 订单查询 | 菜品发布 | 财务对账'
        }
      ],
      active:0
    };
  },
  mounted() {},
  methods: {
    selectBtn(e){
      this.active = e
    },
    goAdmin(e){
      window.open(`https://tour.zunlvyun.com/index.html#/login?key=${e}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.ServiceObject{
  height: 870px;
  background-image: url("~@/assets/image/content/bg1.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 148px;
  &-title{
    width: 448px;
    height: 80px;
    padding-top: 52px;
  }
  &-box{

    height: 160px;
    gap: 78px;
    position: relative;
    &-bg{
      width: 100%;
      height: 1px;
      background: #E1E1E1;
      position: absolute;
      top: 118px;
      left: 0;
    }
    &-button{
      cursor: pointer;
      width: 120px;
      height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      z-index: 2;
      .icon-color{
        color: #C7C9D9;
        margin-top: 60px;
      }
      &-line{
        width: 120px;
        height: 5px;
        background: rgba(0,0,0,0);
      }
      .name{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C7C9D9;
      }
    }
  }
  &-content{
    padding: 0 148px;
    width: 100%;
    height: 450px;
    margin-top: 70px;
    gap: 30px;
    &-img{
      width: 100%;
      height: 450px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    &-info{
      width: 100%;
      height: 450px;
      .title-box{
        width: 100%;
        height: 95px;
        margin-left: 57px;
        position: relative;
        .title-bg{
          font-size: 139px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e7e9eb;
          letter-spacing: 2px;
          line-height: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .title{
          font-size: 47px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #333333;
          position: absolute;
          bottom: 0;
          z-index: 2;

          &-english{
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }

      .line{
        flex: 1;
        height: 1px;
        background: #E1E1E1;
        margin: 10px 0 40px 57px;
      }

      .info-box{
        flex: 1;
        height: 164px;
        font-size: 26px;
        font-weight: 400;
        color: #666666;
        margin-left: 57px;
      }
      .btn{
        width: 202px;
        height: 65px;
        background: #386DCC;
        border-radius: 4px;
        border: 1px solid #979797;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 65px;
        text-align: center;
        margin: 34px 0 0 57px;
        cursor:pointer;
      }
    }
  }

}
.f-r-c{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>