<template>
  <div class="nav">
    <div class="nav-title">
      <div class="nav-title-text"></div>
    </div>
    <div class="nav-group">
      <el-menu
          default-active="home"
          mode="horizontal"
          background-color="rgba(0,0,0,0)"
          text-color="#FFFFFF"
          @select="handleSelect"
      >
        <el-menu-item v-for="item in nav" :key="item" :index="item.key">{{ item.name }}</el-menu-item>

      </el-menu>
    </div>

    <div class="nav-login" @click="isSHowFun">
      登录
      <div class="login-btn" v-show="isShow">
        <div v-for="item in button" :key="item.key" @click="goAdmin(item.key)">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavView",
  components: {

  },
  computed: {

  },
  data() {
    return {
      nav:[
        {
          name:'首页',
          key:'home',
        },
        {
          name:'服务对象',
          key:'service',
        },
        {
          name:'平台介绍',
          key:'platform'
        },
        {
          name:'合作伙伴',
          key:'cooperation'
        },
        {
          name:'社会效益',
          key:'solution'
        },

      ],
      isShow:false,
      button:[
        {
          icon:'jingqu',
          name:'景区',
          english:'ONE',
          nameEenglish:'SCENIC',
          key:'scenic',
          img:require('@/assets/image/content/scenic.jpg'),
          info:'景区门票发布  | 景区商品发布 | 销售渠道对接与管理 | 景区客流及门票销售数据'
        },
        {
          icon:'lvxingshe',
          name:'旅行社',
          english:'TWO',
          nameEenglish:'TRAVEL',
          key:'travel',
          img:require('@/assets/image/content/travel.png'),
          info:'门票预定  | 酒店预定 | 组合商品预定 | 订单查询 | 对账结算',
        },
        {
          icon:'jiudian',
          name:'酒店',
          english:'THREE',
          nameEenglish:'HOTEL',
          key:'hotel',
          img:require('@/assets/image/content/hotel.jpg'),
          info:'酒店商品发布  | 酒店组合商品发布 | 销售渠道对接与管理 | 全渠道订单管理 '
        },
        {
          icon:'gouwu',
          name:'购物店',
          english:'FOUR',
          nameEenglish:'SHOPPING',
          key:'shopping',
          img:require('@/assets/image/content/shopping.png'),
          info:'购物店发布  | 购物店发布 | 销售渠道对接与管理'
        },
        {
          icon:'canyin',
          name:'餐饮',
          english:'FIVE',
          nameEenglish:'RESTAURANT',
          key:'restaurant',
          img:require('@/assets/image/content/restaurant.png'),
          info:'订单和菜品统一管理及销售'
        }
      ],
    };
  },
  mounted() {},
  methods: {
    handleSelect(e){
      this.$store.commit('setName', e);
    },
    isSHowFun(){
      this.isShow = !this.isShow
    },
    goAdmin(e){
      window.open(`https://tour.zunlvyun.com/index.html#/login?key=${e}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.nav{
  width: 100%;
  height: 88px;
  background: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  &-title{
    @media screen and (min-width: 1400px){
      flex: 1;
    }
    @media screen and (max-width: 1400px){
      width: 450px;
      margin-left: 30px;
    }
    height: 88px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 82px;
    //margin-left: 148px;
    background: url("~@/assets/image/header/title.png");
    background-repeat: no-repeat;
    background-size: 445px 49px;
    background-position: center center;

    &-text{
      color: rgba(0,0,0,0);
    }
  }
  &-group{
    width:630px;
    flex: 1;

    :deep(.el-menu){
      font-size: 20px !important;
      border-bottom: solid 0 #f2f1f6 !important;
      .el-menu-item{
        font-size: 20px !important;
        margin-right: 16px;
      }
      .el-menu-item:hover{
        outline: 0 !important;
        color: #409EFF !important;
        background-color: rgba(0,0,0,0);
      }
      .is-active{
        border-bottom: solid 0 rgba(0,0,0,0);
      }

    }
  }


  &-login{
    width: 82px;
    height: 41px;
    background: rgba(255,255,255,0.3);
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 41px;
    text-align: center;
    position: absolute;
    right: 56px;
    cursor: pointer;
    &:hover{
      background: #409EFF;
    }
    .login-btn{
      width: 100px;
      height: 210px;
      position: absolute;
      left: -10px;
      top: 50px;
      background: rgba(0,0,0,0.3);
      border-radius: 6px;
      div{
        &:hover{
          background: #409EFF;
        }
      }

    }
  }
}

</style>