<template>
  <div class="CooperativePartner">
    <div class="CooperativePartner-title">
      <img :src="title" alt="">
    </div>
    <div class="CooperativePartner-banner">
      <div id="CooperativePartner" class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in info" :key="index">
            <img :src="item.img" alt="">
            <div class="title">{{ item.title }}</div>
            <div class="introduce">{{ item.introduce }}</div>
<!--            <div @click="go(item.link)" class="btn">去看看</div>-->
          </div>

        </div>
        <div class="swiper-button-next" >

          <app-icon name="right"
                    size="50px"
                    class="icon"
          />
        </div>
        <div class="swiper-button-prev">
          <app-icon name="left"
                    size="50px"
                    class="icon"
          />
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

  </div>
</template>

<script>
import 'swiper/css/swiper.css'  //引入swiper样式
import Swiper from 'swiper';	//引入swiper
export default {
  name: "CooperativePartner",
  components: {

  },
  computed: {

  },
  data() {
    return {
      title:require('@/assets/image/content/cooperative-partner.png'),
      info:[
        {
          img:require('@/assets/image/content/caseimg_01.jpg'),
          title:'苟坝会议会址',
          introduce:'遵义苟坝红色文化旅游景区——国家AAAA级旅游景区，位于贵州省遵义市播州区枫香镇，' +
              '交通便利、风景优美。苟坝村是贵州省“最美红军村落”，这里还保留着原来的黔北民居风貌，青砖黛瓦白粉墙，山清水秀好风光！' +
              '1935年3月，中央红军在这里召开了具有历史意义的“苟坝会议”。景区内重要景点有苟坝会议旧址、苟坝会议陈列馆、毛泽东住地、' +
              '毛泽东小道、长五间(周恩来、朱德住地)、红军医院(黑神庙)，中华苏维埃银行、马灯馆、马鬃岭观景台、红九军团司令部驻地等。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_02.jpg'),
          title:'播州太阳坪',
          introduce:'播州太阳坪景区位于播州区洪关苗族乡小坝场村，景区北临208省道，南至金沙县岩孔镇白沙村干河坝、' +
              '西起金沙县岩孔镇黄沙村罗马田。目前是全区唯一一个保留苗族文化的苗族乡，唯一一个有风车的高山草场，' +
              '唯一一个原生态杜鹃花海。太阳坪景区坚持生态开发为前提，以原生态自然山水风光为主题打造生态自然景区；' +
              '以苗族文化为主导元素，结合洞口苗寨、太阳坪草场、野生映山红、风力发电场等景观综合开发。是集观光揽胜、' +
              '文化体验、休闲度假、养生游憩、科研考察等功能于一体的风景游赏区。自景区自2017年启动建设以来，先后建设了观光车道、' +
              '游客服务中心、旅游厕所、生态停车场、民族特色广场、斗牛场等相关配套设施一应俱全。2021年11月评定为国家4A级旅游景区。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_03.jpg'),
          title:'道真傩城',
          introduce:'中国傩城平均海拔800-1000米，夏季平均温度23.8℃，冬无严寒，夏无酷暑，四季分明，全年宜居，位于金佛山南麓，' +
              '距重庆主城区不超过90分车程钟。是云贵高原嵌入重庆腹地的康养明珠，毗邻国家级自然保护区的世外桃源，' +
              '适合长期居住的养生天堂，立志成为中国康养的领跑者，打造中国康养第一品牌。' +
              '中国傩城也是中国傩戏学会唯一授牌的傩戏文化研究中心，是当今世界最大的傩文化古城。总占地约1700亩，总建筑面积约为50万方，' +
              '融观光、游乐、休闲、运动、会议、度假、体验、居住等多种旅游功能，更是起着游客集散服务的重要作用。' +
              '中国傩城以“傩”文化为主题，国家级非物质文化遗产——傩戏，被称为“人类文明的活化石”，是远古时代图腾崇拜时期的一种祭祀仪式。' +
              '傩戏、傩祭、傩舞承载着仡佬族人祈福纳祥，祛病消灾的美好愿景。项目整体布局契合金、木、水、火、土五行相生原理，' +
              '融入福、禄、寿、喜、财的祥瑞元素，规划有三大古街（竹王古街、夜郎古街、山王古街）、五大广场（福、禄、寿、喜、财广场）、' +
              '三大公园（“魔幻岛”生态亲子主题乐园、“傩戏王国主题公园”、湿地公园）。三幺台民俗晚宴是仡佬族最高待客礼仪习俗，“三”，' +
              '指的是三台席，即茶席、酒席和饭席。“幺台”，正安、道真、务川一带地域土语，“结束”或“完成”的意思。“三幺台”，意思是一次宴席' +
              '，要经过茶席、酒席、饭席才结束，故称“三幺台”。“三幺台”习俗，流布于黔北的正安县、道真、务川仡佬族苗族自治县的部分地区。' +
              '玻璃天空廊桥，高度落差近180米，可俯视整个傩城，桥面长度近300米，是游客来傩城游玩的必去之地，廊桥另一端为配备有玻璃滑道，' +
              '让游客体验丛林穿越的乐趣。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_04.jpg'),
          title:'凤冈万佛峡谷',
          introduce:'万佛峡谷景区位于凤冈县城南部，横跨琊川镇和蜂岩镇。在凤冈县南部的万佛山自然保护区核心区内猪槽坝一带，' +
              '源头于凤冈县万佛山省级森林公园南麓茅台坝东面蚂蝗沟，蜿蜒向东近30千米在蜂岩镇小河村与辉塘河汇合，' +
              '中部与万佛山东北麓的石碗溪汇合，峡谷长约46千米，源头谷底海拔900至1000米，出口谷底海拔500米，谷深多在150至200米以上。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_05.jpg'),
          title:'汇川海龙屯',
          introduce:'海龙屯是国家AAAA级景区，海龙屯景区位于贵州省遵义市老城西北约28公里的龙岩山巅，2015年7月4日以“中国土司遗址”' +
              '之名申遗成功，是目前贵州首个且唯一的世界文化遗产。海龙屯土司遗址是杨氏土司家族统治古播州长达725年的重要遗存，' +
              '也是当今中国乃至亚洲保存完好的中世纪军事城堡遗址。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_06.jpg'),
          title:'汇川娄山关',
          introduce:'娄山关位于大娄山脉中段，贵州省遵义市汇川区板桥镇境内。关口海拔1277米，主峰笋子山海拔1779米。' +
              '自古为川黔交通要隘，历来为兵家必争之地，素有“一夫当关，万夫莫开”之称。1935年1月，中央红军一克娄山关，' +
              '确保了遵义会议的胜利召开。1935年2月，中央红军再克娄山关，拉开了遵义战役的序幕。毛泽东同志在此写下了著名词作《忆秦娥·' +
              '娄山关》。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_07.jpg'),
          title:'湄潭茶海',
          introduce:'中国茶海也叫万亩茶海，位于贵州省湄潭县永兴镇境内，这是目前世界上面积最大的茶海，' +
              '现在连片茶园近4.3万亩，已规划为中国茶海公园。1986年，时任贵州省委书记胡锦涛同志在湄潭考察时称之为“万亩茶海”，' +
              '2010年获得“贵州十大魅力旅游景区”称号。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_08.jpg'),
          title:'绥阳红果树',
          introduce:'红果树AAAA景区位于贵州省遵义市绥阳县大路槽乡。景区交通位置条件优越：距绥阳县12公里 ，' +
              '距遵义市42公里 距贵阳180公里 距重庆270公里，40分钟就可到达遵义机场，独具空中走廊优势。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_09.jpg'),
          title:'绥阳十二背后',
          introduce:'十二背后双河洞旅游景区位于贵州省遵义市绥阳县，离绥阳县三十余公里，离遵义市区七十多公里，是国家AAAA级景区。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_10.jpg'),
          title:'桐梓杉坪',
          introduce:'杉坪景区位于遵义市桐梓县娄山关镇，占地9800亩，包括4A级景区黔北花海，芳草地酒店及特色名宿，' +
              '独栋小木屋110余间，有独门独户圆形木屋大床，也有居家式的双卧套房，还有现代式高级四合院私人订制，' +
              '黔北花海又名杉坪花海，这里花开不断，春有艳丽亮眼的映山红红遍山野，夏有层层叠起的薰衣草延绵不断，' +
              '秋有热情不减的向日葵让人沉醉，冬有片片樱花让花海美丽再续 目前景区有滑翔伞 山地车 五彩滑道 玻璃水滑 ' +
              '卡丁车 植物迷宫 孔雀家园等娱乐设施，附近周边游有红色景点 有娄山关 以及小西湖',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_11.jpg'),
          title:'桐梓水银河',
          introduce:'贵州水银河大峡谷景区位于贵州省遵义市桐梓县木瓜镇水银村;水银河又名木瓜河，系长江流域綦江右岸一级支流。' +
              '起源于桐梓县黄莲乡，流经木瓜镇，流域面积388平方公里，源头海拔1780米，河口海拔350米，总落差1430米，' +
              '主河道全长38公里，平均坡降22‰;景区南距贵阳260公里，遵义150公里，北距重庆主城170公里；距离习正（习水——正安）' +
              '高速水银河（木瓜）下道口9公里，整个景区涵盖水银河上游30余公里，目前开发出约十里长峡。有大瀑布10余处，小瀑布几十处' +
              '，各种苔藓景观和钟乳石景观不计其数。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_12.jpg'),
          title:'务川仡佬文化',
          introduce:'务川仡佬文化旅游景区，位于贵州省务川自治县大坪街道洪渡河畔，距务川县城仅5㎞左右,距世界自然遗产重庆武隆约120㎞,' +
              '距历史文化名城遵义180㎞。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },
        {
          img:require('@/assets/image/content/caseimg_13.jpg'),
          title:'正安九道水',
          introduce:'九道水景区位于正安县桴焉镇自然保护区境内，景区系贵州省“5个100工程”之一，总面积2167公顷，平均海拔1600米，' +
              '森林覆盖率达90%，被林业和生物专家誉为“贵州生态的典型模式”。景区集山、林、水、溶洞及地方特色人文等融为一体，' +
              '具有幽、险、峻、秀、旷、野、古、特等特色，有较高的生态旅游和科普教育价值，是养生度假、消夏避暑、' +
              '研学探险等旅游活动的理想胜地。',
          link:'https://zy.xlvcheng.com/index.html#/login'
        },


      ],

    };
  },
  mounted() {
    new Swiper('#CooperativePartner', {
      slidesPerView: 4,
      spaceBetween: 28,
      slidesOffsetBefore : 148,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
  methods: {
    go(e){
      window.open(e)
    }
  },
};
</script>

<style lang="scss" scoped>
.CooperativePartner{
  width: 100%;
  height: 890px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #F4F6F8;
  &-title{
    width: 448px;
    height: 80px;
    padding-top: 52px;
  }
  &-banner{
    margin-top: 59px;
    width: 100%;
    height: 1057px;
    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      width: 500px;
      height: 510px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      img{
        width: 100%;
        height: 233px;
      }
      .title{
        width: 100%;
        margin-top: 50px;
        font-size: 34px;
        font-weight: 500;
        color: #000000;
        letter-spacing: 1px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
      .introduce{
        padding: 12px 45px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        height: 96px;
        line-height: 34px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        -webkit-line-clamp: 3;
      }
      .btn{
        margin-top: 60px;
        width: 234px;
        height: 71px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 71px;
        cursor: pointer;
        &:hover{
          color: #ffffff;
          border: 1px solid #E2E2E2;
          background: #386DCC;
        }
      }
    }
    .swiper-container-horizontal > .swiper-pagination-progressbar{
      position: absolute;
      top: 600px;
    }
    .swiper-button-next{
      height: 50px;
      position: absolute;
      top: 656px;
      transform: translate(100%);
      left: 50%;
      &:after{
        display: none;
      }
    }
    .swiper-button-prev{
      height: 50px;
      position: absolute;
      top: 656px;
      transform: translate(-100%);
      left: 50%;
      &:after{
        display: none;
      }
    }
  }

}
.f-r-c{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
</style>