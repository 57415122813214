<template>
  <div class="SolutionView">
    <div class="SolutionView-title">
      <img :src="title" alt="">
    </div>
    <div class="SolutionView-text">
      遵旅云搭建遵义智慧旅游平台，是实现政务部门对涉旅企业的精准施政，提高涉旅企业智慧化建设和运营管理，
      提升游客满意度、企业知名度和服务水平。所产生的社会效益是全角色、全过程、全产业、全社会的构建的是城与城，
      企业与企业，人与人之间的共创、共享、共建、共赢。
    </div>
    <div class="SolutionView-content">
      <div class="box" v-for="(item,index) in data"
           :key="index"
           @mouseenter="mouseenter(index)"
           :class="active == index?'hover':''">
        <img :src="item.img" alt="">
        <div class="top-mask"
             v-show="index != active"
             :style="'background: linear-gradient(180deg, ' + item.color + ' 0%, '+ item.color2 +' 100%);'"
        >
          <div class="title">{{ item.title }}</div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <div class="bottom-mask"
             v-show="index == active"
        >
          <div class="title">{{ item.title }}</div>
          <div class="tag">{{ item.tag }}</div>
          <div class="info">{{ item.info }}</div>
        </div>
      </div>
<!--      <div class="box">-->
<!--        <img src="@/assets/image/content/programme-bg2.png" alt="">-->

<!--      </div>-->
<!--      <div class="box">-->
<!--        <img src="@/assets/image/content/programme-bg3.png" alt="">-->

<!--      </div>-->
    </div>
  </div>
</template>

<script>

export default {
  name: "SolutionView",
  components: {

  },
  computed: {

  },
  data() {
    return {
      title:require('@/assets/image/content/solution.png'),
      data:[
        {
          title:'政府行业管理部门',
          img:require('@/assets/image/content/programme-bg1.png'),
          tag:'地方平台公司',
          info:'对资源、数据、服务的统一管控，将资金、数据、信息留在当地，实现能力资产化、数据资产化，金融支撑化，提升旅游目的地游客的服务质量及满意度，助力全域旅游产业的发展',
          color:'rgba(26, 86, 219, 1)',
          color2:'rgba(27,85,219,0.32)'
        },
        {
          title:'采购商',
          img:require('@/assets/image/content/programme-bg2.png'),
          tag:'旅行社 / OTA',
          info:'助力企业快速构建APP，高效实现移动数字化转型',
          color:'rgba(86, 61, 233, 1)',
          color2:'rgba(86,61,233,0.32)'
        },
        {
          title:'供应商',
          img:require('@/assets/image/content/programme-bg3.png'),
          tag:'景区/酒店/餐饮/交通',
          info:'提升服务能力，促进产业升级',
          color:'rgba(146,36,216, 1)',
          color2:'rgba(146,36,216,0.32)'
        }
      ],
      active: 0,
    };
  },
  mounted() {

  },
  methods: {
    mouseenter(e){
      this.active = e
    }
  },
};
</script>

<style lang="scss" scoped>
.SolutionView{
  padding: 0 148px;
  height: 925px;
  background-image: url("~@/assets/image/content/bg3.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &-title{
    width: 448px;
    height: 80px;
    padding-top: 52px;
  }
  &-text{
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 33px;
  }
  &-content{
    margin-top: 53px;
    height: 588px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    .box{
      @media screen and (min-width: 1700px){
        width: 300px;
      }
      @media screen and (max-width: 1700px){
        width: 200px;
      }
      height: 588px;
      border-radius: 11px;
      overflow: hidden;
      transition: 0.8s;
      position: relative;
      img{
        height: 588px;
        width: auto;
      }
      .top-mask{
        position: absolute;
        transition: width 0.8s;
        top: 0;
        left: 0;
        width: 100%;
        height: 588px;
        background: linear-gradient(180deg, #1A56DB 0%, rgba(27,85,219,0.32) 100%);
        .title{
          font-size: 30px;
          color: #FFFFFF;
          margin: 114px 0 0 30px;
        }
        .tag{
          font-size: 20px;
          color: #FFFFFF;
          margin: 14px 0 0 30px;
        }

      }
      .bottom-mask{
        width: 100%;
        //height: 219px;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 26%, rgba(0,0,0,0.76) 100%);
        border-radius: 11px;
        position: absolute;
        bottom: 0;
        left: 0;
        .title{
          font-size: 30px;
          color: #FFFFFF;
          margin: 37px 0 0 30px;
        }
        .tag{
          font-size: 20px;
          color: #FFFFFF;
          margin: 14px 0 0 30px;
        }
        .info{
          background: linear-gradient(90deg, rgba(255,255,255,0.8) 0% , rgba(255,255,255,0) 100%);
          line-height: 30px;
          font-size: 20px;
          color: #000000;
          padding: 0 90px 0 16px;
          margin: 14px 14px 30px 30px;
        }
      }
    }
    .hover{
      @media screen and (min-width: 1400px){
        width: 986px;

      }
      @media screen and (max-width: 1400px){
        width: 686px;
      }
      transition: 0.8s;

    }
  }
}
.f-r-c{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
</style>