<template>
  <div id="swipercom">
    <img class="swiper-top-img" src="@/assets/image/header/banner-top.png" alt="">
    <div class="swiper-container" id="swiperIndex">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,i) in imgs" :key="i">
          <img class="swiper-img" :src="item.pic" alt="">
        </div>
      </div>
      <div class="swiper-pagination">

      </div>
      <div class="swiper-button-next"> </div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'  //引入swiper样式
import SwiperBanner from 'swiper';	//引入swiper
export default {
  name: "BannerView",
  data(){
    return{
      imgs:[
        {pic:require('@/assets/image/header/11.jpg')},
        {pic:require('@/assets/image/header/22.jpg')},
        {pic:require('@/assets/image/header/55.jpg')},
      ]
    }
  },
  mounted() {
    new SwiperBanner('#swiperIndex',{
      effect : 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,//1秒切换一次
      },
      loop: true, // 循环模式选项
      pagination:{
        el:".swiper-pagination",//换页器与哪个标签关联
        clickable:true//分页器是否可以点击
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
}
</script>

<style lang="scss">
#swipercom{
  width: 100%;
  position: relative;
  .swiper-top-img{
    position: absolute;
    z-index: 10;
    width: 966px;
    height: 186px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #swiperIndex.swiper-container{
    width: 100%;

    .swiper-slide {

      .swiper-img{
        width: 100%;
      }
    }

    .swiper-pagination-bullet{
      width: 64px;
      height: 6px;
      background: #FFFFFF;
      border-radius: 0;
    }
    .swiper-button-prev:after, .swiper-button-next:after{
      color: #ffffff;
      padding: 20px 20px;
      background: rgba(0,0,0,0.31);
    }

  }
}
</style>
