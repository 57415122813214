import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import store from './store'

import appIcon from '@/components/app-icon/app-icon'


const app = createApp(App)

app.use(store)
    .use(router)
    .use(ElementPlus, {
        locale: zhCn,
        size: 'large'
    })
    .component('app-icon', appIcon)
    .mount('#app')