<template>
  <div class="content">
    <service-object id="service"></service-object>
    <platform-introduction id="platform"></platform-introduction>
    <cooperative-partner id="cooperation"></cooperative-partner>
    <solution-view id="solution"></solution-view>
  </div>
</template>

<script>
import ServiceObject from './ServiceObject'
import PlatformIntroduction from './PlatformIntroduction.vue'
import SolutionView from './SolutionView.vue'
import CooperativePartner from './CooperativePartner.vue'
import { mapState } from "vuex";

export default {
  name: "ContentView",
  components: {
    ServiceObject,
    PlatformIntroduction,
    SolutionView,
    CooperativePartner,
  },
  computed: {
    ...mapState(["name"]),

  },
  watch: {
    name: {
      immediate: true,
      handler(value) {
        this.goLoc(value)
      }
    },
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    goLoc(e){
      let id = '#' + e
      if (e!='home'){
        console.log(document.querySelector(id))
        document.querySelector(id).scrollIntoView({
          behavior: "smooth"
        });
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
}
</style>