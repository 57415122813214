<template>
  <div class="router-box">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "App",
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss">
@import "//at.alicdn.com/t/c/font_3254884_3u6drm3o378.css";
@import "@/assets/style/theme.scss";
@import "@/assets/style/default.scss";
.router-box{
  min-width: 1300px;
}
</style>
